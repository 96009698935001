<template>
  <div class="landing-view font-kanit">
    <landing-header class="p-absolute transparent-bg" />
    <div class="landing-view-content">
      <v-parallax
        dark
        :srcset="utility.getImgSrcSet('landing-bg', 'jpg')"
        height="946"
        alt=""
      >
        <div class="text-center">
          <img width="269"
            :src="utility.getImgSrc('logo-sphere-alt.png', true)"
            alt="sphere"
          />
        </div>
        
        <!-- Introduction message -->
        <div class="landing-intro-message slide-fade-y px-6">
          <div class="font-weight-medium my-6" style="font-size: 45px; line-height: 57px;">
            {{ $t('landing.sphereTopic') }}
          </div>
          <div class="font-weight-light text-lg mt-3" v-html="$t('landing.sphereDesc')">
          </div>
          <v-btn
            x-large
            width="192"
            color="secondary-variant"
            elevation="4"
            class="mt-11 text-xxl text-white rounded-lg"
            @click="$router.push({ name: 'dashboard' })"
          >
            {{ $t('landing.start') }}
          </v-btn>
        </div>
      </v-parallax>
      <!-- tools -->
      <div class="landing-tools landing-bg-accent px-4" style="margin-top: -60px;padding-top: 100px;padding-bottom: 63px;">
        <v-container class="mt-15 slide-fade-y">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-img height="62" contain class="cursor-pointer mx-auto" :src="utility.getImgSrc('tool-map.svg')" @click="scrollTo('.landing-api')" alt="Map API" />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-img height="62" contain class="cursor-pointer mx-auto" :src="utility.getImgSrc('tool-portal.svg')" @click="scrollTo('.landing-portal')" alt="Open Portal" />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-img height="62" contain class="cursor-pointer mx-auto" :src="utility.getImgSrc('tool-cube.svg')" @click="scrollTo('.landing-cube')" alt="Data Cube" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- Comprehensive Map API -->
      <div class="landing-api landing-bg-accent d-flex px-4 pb-0 px-sm-12 mx-auto">
        <kinesis-container class="p-absolute" event="scroll">
          <kinesis-element :strength="100" >
            <div class="bg-circle"></div>
          </kinesis-element>
        </kinesis-container>
        <v-row class="width-full slide-fade-y justify-center">
          <v-col xs="12" lg="5" class="flex-grow-1 text-left pr-8" style="max-width: 500px;">
            <div>
              <div class="p-relative">
                <kinesis-container class="p-absolute bg-text" event="scroll">
                  <kinesis-element :strength="-30" >
                    APIs
                  </kinesis-element>
                </kinesis-container>
                <div class="text-transform-uppercase font-weight-medium text-center" style="font-size: 35px;">
                  APIs
                </div>
              </div>
            </div>
            <div class="mt-4" style="font-size: 35px; line-height: 42px;">
              {{ $t('landing.apiTopic') }}
            </div>
            <div class="text-lg text--secondary mt-7" style="line-height: 32px;">
              <div>{{ $t('landing.apiDesc') }}</div>
              <div class="mt-4">{{ $t('landing.daasDesc') }}</div>
            </div>
            <div>
              <v-btn
                x-large
                color="primary-variant"
                elevation="4"
                class="btn-explore"
                @click="utility.openLink(`${baseURI}docs/`)"
              >
                Explore the API <img width="24" class="ml-4" :src="utility.getImgSrc('arrow-right-white.svg')" alt="" />
              </v-btn>
            </div>
          </v-col>
          <v-col xs="12" lg="7" class="pl-lg-8 px-sm-3 px-0">
            <!-- Frameworks -->
            <div class="landing-framework landing-bg-accent text-left"
              style="padding: 0 0 117px;"
            >
              <div class="d-sm-flex justify-center mt-15 mt-lg-0">
                <div class="mr-0 mr-sm-8">
                  <div class="card-framework">
                    <img class="ml-4 mb-7" :src="utility.getImgSrc('api-map.svg')" alt="" />
                    <div class="card-framework-label">Map APIs</div>
                    <div class="mt-3 text--secondary">
                      <span v-html="$t('landing.apiBoxDesc')"></span>
                      <span class="ml-1">
                        <a :href="`${baseURI}docs/js/createmap`" @click="utility.openLink">JavaScript</a>,
                        <a :href="`${baseURI}docs/android/getting-started`" @click="utility.openLink">Android</a>,
                        <a :href="`${baseURI}docs/ios/getting-started`" @click="utility.openLink">iOS</a>,
                        <a :href="`${baseURI}docs/flutter/getting-started`" @click="utility.openLink">Flutter</a>,
                        <a :href="`${baseURI}docs/react-native/getting-started`" @click="utility.openLink">React Native</a>,
                        <a :href="`${baseURI}docs/web-service/search`" @click="utility.openLink">Search API</a>,
                        <a :href="`${baseURI}docs/web-service/embed-map`" @click="utility.openLink">Embed</a> /
                        <a :href="`${baseURI}docs/web-service/static-map`" @click="utility.openLink">Static Map API</a>
                      </span>
                      <v-btn
                        color="primary-variant" elevation="4"
                        class="width-full text-md rounded-lg py-2 mt-8"
                        style="height: unset"
                        @click="utility.openLink(VUE_APP_QUICK_DEMO_URL)"
                      >
                        <div class="text-white" style="line-height: 1.375rem;">
                          <div class="text-lg font-weight-regular">Tiled Maps API</div>
                          <div class="text-sm font-weight-light">(Street, Satellite, Aerial)</div>
                        </div>
                        <img width="24" class="ml-4" :src="utility.getImgSrc('arrow-right-white.svg')" alt="" />
                      </v-btn>
                    </div>
                  </div>
                  <div class="card-framework mt-8">
                    <img class="ml-n2 mt-n2 mb-2" :src="utility.getImgSrc('api-place.svg')" alt="" />
                    <div class="card-framework-label">Places</div>
                    <div class="mt-3 text--secondary">
                      <span v-html="$t('landing.placeBoxDesc')"></span>
                      <span>
                        <a :href="`${baseURI}docs/web-service/search`" @click="utility.openLink">Place Search API</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div :style="{marginTop: $vuetify.breakpoint && $vuetify.breakpoint.smAndUp ? '132px' : '32px'}">
                  <div class="card-framework">
                    <img class="ml-2 mb-5" :src="utility.getImgSrc('api-route.svg')" alt="" />
                    <div class="card-framework-label">Routes</div>
                    <div class="mt-4 text--secondary" v-html="$t('landing.routeBoxDesc')"></div>
                    <div class="mt-3 text--secondary">
                      <span v-html="$t('landing.routeBoxDesc')"></span>
                      <span>
                        <a :href="`${baseURI}docs/web-service/routing`" @click="utility.openLink">Routing API</a>,
                        <a :href="`${baseURI}docs/web-service/routing-matrix`" @click="utility.openLink">Distance Matrix API</a>
                      </span>
                    </div>
                  </div>
                  <div class="card-framework mt-8">
                    <img class="ml-1 mb-6" :src="utility.getImgSrc('api-info.svg')" alt="" />
                    <div class="card-framework-label">Information</div>
                    <div class="mt-3 text--secondary">
                      <span v-html="$t('landing.infoBoxDesc')"></span>
                      <span>
                        <a :href="`${baseURI}docs/web-service/elevation-information`" @click="utility.openLink">Elevation API</a>,
                        <a :href="`${baseURI}docs/web-service/disaster-information`" @click="utility.openLink">Disasters API (Flood, Fire, Drought)</a>,<br />
                        <a :href="`${baseURI}docs/web-service/crop-information`" @click="utility.openLink">Crop API (Cost & Prices, Prediction)</a>,
                        <a :href="`${baseURI}docs/web-service/land-suitability`" @click="utility.openLink">Land Suitability API</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- Open Portal -->
      <div
        class="landing-portal d-flex px-4 px-sm-12 mx-auto"
      >
        <kinesis-container class="p-absolute" event="scroll" style="right:64px;">
          <kinesis-element :strength="100" >
            <div class="bg-circle"></div>
          </kinesis-element>
        </kinesis-container>
        <v-row class="width-full slide-fade-y">
          <v-col sm="5" md="6" class="d-none d-sm-block pr-8">
            <v-img max-width="608" class="flex-grow-1 rounded-xl"
              :aspect-ratio="110/131"
              :src="utility.getImgSrc('landing-portal.jpg')"
            />
          </v-col>
          <v-col xs="12" md="6" sm="7" class="flex-grow-1 text-left pl-8">
            <div class="p-relative">
              <kinesis-container class="p-absolute bg-text" event="scroll">
                <kinesis-element :strength="-30" >
                  Map Maker
                </kinesis-element>
              </kinesis-container>
              <div class="text-transform-uppercase font-weight-medium text-center" style="font-size: 35px;">
                Map Maker
              </div>
            </div>
            <div class="mt-4" style="font-size: 35px; line-height: 42px;">
              {{ $t('landing.portalTopic') }}
            </div>
            <div class="text-lg text--secondary mt-7" style="line-height: 32px;">
              {{ $t('landing.portalDesc') }}
            </div>
            <div>
              <v-btn
                x-large
                color="primary-variant"
                elevation="4"
                class="btn-explore"
                @click="utility.openLink(VUE_APP_PORTAL_URL)"
              >
                Explore the Portal <img width="24" class="ml-4" :src="utility.getImgSrc('arrow-right-white.svg')" alt="" />
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- Open Data Cube -->
      <kinesis-container class="p-absolute" event="scroll">
        <kinesis-element :strength="100" >
          <div class="bg-circle"></div>
        </kinesis-element>
      </kinesis-container>
      <div class="landing-cube d-flex px-4 px-sm-12 mx-auto">
        <v-row class="width-full slide-fade-y">
          <v-col xs="12" md="6" sm="7" class="flex-grow-1 text-left pr-8">
            <div class="p-relative">
              <kinesis-container class="p-absolute bg-text" event="scroll">
                <kinesis-element :strength="-30" >
                  Data Cube
                </kinesis-element>
              </kinesis-container>
              <div class="text-transform-uppercase font-weight-medium text-center" style="font-size: 35px;">
                Data Cube
              </div>
            </div>
            <div class="mt-4" style="font-size: 35px; line-height: 42px;">
              {{ $t('landing.cubeTopic') }}
            </div>
            <div class="text-lg text--secondary mt-7" style="line-height: 32px;">
              {{ $t('landing.cubeDesc') }}
            </div>
            <div>
              <v-btn
                x-large
                color="primary-variant"
                elevation="4"
                class="btn-explore"
                href="https://datacube.gistda.or.th/"
                target="_blank"
              >
                Explore the Data Cube <img width="24" class="ml-4" :src="utility.getImgSrc('arrow-right-white.svg')" alt="" />
              </v-btn>
            </div>
          </v-col>
          <v-col sm="5" md="6" class="d-none d-sm-block pl-8">
            <v-img max-width="608" class="flex-grow-1 rounded-xl"
              :aspect-ratio="110/131"
              :src="utility.getImgSrc('landing-odc.jpg')"
            />
          </v-col>
        </v-row>
      </div>
      <!-- Showcases -->
      <div class="landing-showcase landing-bg-accent" style="padding-bottom: 104px;">
        <div class="width-full slide-fade-y">
          <div class="p-relative" style="padding: 70px 0;">
            <kinesis-container class="p-absolute bg-text" event="scroll">
              <kinesis-element :strength="-30" >
                Solutions
              </kinesis-element>
            </kinesis-container>
            <div class="text-center text-transform-uppercase font-weight-medium" style="font-size: 35px;">
              Solutions
            </div>
          </div>
          <div class="p-relative d-flex justify-center">
            <v-btn outlined fab small elevation="0" class="carousel-btn left-btn" @click="changeSlide(-1)">
              <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn outlined fab small elevation="0" class="carousel-btn right-btn" @click="changeSlide(1)">
              <v-icon dark>mdi-arrow-right</v-icon>
            </v-btn>
            <div class="my-carousel d-flex text-left px-4 pb-4">
              <v-card class="card-showcase" elevation="0" @click="utility.openLink(VUE_APP_WHERE_URL)">
                <div>
                  <v-img width="368" height="231" contain :src="utility.getImgSrc('showcase-where.png')" alt="Where" />
                  <div class="card-showcase-label">Where</div>
                  <div class="card-showcase-detail mt-4 mb-6">
                    ระบบการค้นหาเส้นทางแบบ Turn by turn navigation ที่ผู้ใช้งานสามารถป้อนจุดเริ่มต้น และจุดปลายทางได้ พร้อมทั้งมีเสียงนำทางประกอบ สามารถ download ได้ทั้งบน iOS และ Android
                  </div>
                </div>
                <div>
                  <v-divider></v-divider>
                  <div class="text-right font-weight-light my-3">
                    <span>{{ $t('landing.seeMore') }}</span>
                    <img width="17" class="ml-3 mr-5" :src="utility.getImgSrc('arrow-right.svg')" alt="" />
                  </div>
                </div>
              </v-card>
              <v-card class="card-showcase ml-11" elevation="0" @click="utility.openLink('https://opendata.gistda.or.th/better_crop')">
                <div>
                  <v-img width="368" height="231" contain :src="utility.getImgSrc('showcase-crop@2x.png')" :srcset="utility.getImgSrcSet('showcase-crop')" alt="Better Crop" />
                  <div class="card-showcase-label">Better Crop</div>
                  <div class="card-showcase-detail mt-4 mb-6">
                    <div>ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่เหมาะสมในการปลูกพืชเศรษกิจ 6 ชนิด</div>
                    <div class="mt-2">ได้แก่ อ้อย ข้าว ข้าวโพด มันสำปะหลัง ยางพารา และปาล์ม </div>
                    <div class="mt-2">เพียงผู้ใช้งานวาดขอบเขตบริเวณพื้นที่ที่สนใจ ระบบจะทำการการวิเคราะห์พื้นที่ และแสดงความเหมาะสมของพืชแต่ละชนิด รวมถึงราคาต้นทุนในการเพาะปลูกและราคาขายในปัจจุบัน ได้อีกด้วย</div>
                  </div>
                </div>
                <div>
                  <v-divider></v-divider>
                  <div class="text-right font-weight-light my-3">
                    <span>{{ $t('landing.seeMore') }}</span>
                    <img width="17" class="ml-3 mr-5" :src="utility.getImgSrc('arrow-right.svg')" alt="" />
                  </div>
                </div>
              </v-card>
              <v-card class="card-showcase ml-11" elevation="0" @click="utility.openLink('https://opendata.gistda.or.th/drought-info')">
                <div>
                  <v-img width="368" height="231" contain :src="utility.getImgSrc('showcase-drought@2x.png')" :srcset="utility.getImgSrcSet('showcase-drought')" alt="Drought Information" />
                  <div class="card-showcase-label">Drought Information</div>
                  <div class="card-showcase-detail mt-4 mb-6">
                    <div>ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลภัยแล้งซ้ำซาก 10 ปี (พ.ศ. 2548 -2557) ด้วยการแสดงในรูปแบบแผนที่ออนไลน์</div>
                    <div class="mt-2">ผู้ใช้งานสามารถเลือกแสดงผลเปอร์เซ็นต์ของระดับภัยแล้งซ้ำซากในระดับตำบล โดยการเลือกจังหวัดและอำเภอที่สนใจ</div>
                  </div>
                </div>
                <div>
                  <v-divider></v-divider>
                  <div class="text-right font-weight-light my-3">
                    <span>{{ $t('landing.seeMore') }}</span>
                    <img width="17" class="ml-3 mr-5" :src="utility.getImgSrc('arrow-right.svg')" alt="" />
                  </div>
                </div>
              </v-card>
              <v-card class="card-showcase ml-11" elevation="0" @click="utility.openLink('https://opendata.gistda.or.th/flood_info')">
                <div>
                  <v-img width="368" height="231" contain :src="utility.getImgSrc('showcase-flood@2x.png')" :srcset="utility.getImgSrcSet('showcase-flood')" alt="Flood Information" />
                  <div class="card-showcase-label">Flood Information</div>
                  <div class="card-showcase-detail mt-4 mb-6">
                    <div>ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่น้ำท่วมซ้ำซากในรอบ 11 ปี (พ.ศ. 2554 - พ.ศ. 2564)</div>
                    <div class="mt-2">โดยผู้ใช้งานสามารถเลือกสอบถามพื้นที่น้ำท่วมจากการเลือกขอบเขตจังหวัดและอำเภอ</div>
                    <div class="mt-2">หรือสอบถามพื้นที่น้ำท่วมโดยรอบขอบเขตของการปักหมุดบนแผนที่ที่สนใจได้</div>
                  </div>
                </div>
                <div>
                  <v-divider></v-divider>
                  <div class="text-right font-weight-light my-3">
                    <span>{{ $t('landing.seeMore') }}</span>
                    <img width="17" class="ml-3 mr-5" :src="utility.getImgSrc('arrow-right.svg')" alt="" />
                  </div>
                </div>
              </v-card>
              <v-card class="card-showcase ml-11" elevation="0" @click="utility.openLink(VUE_APP_QUICK_DEMO_URL)">
                <div>
                  <v-img width="368" height="231" contain :src="utility.getImgSrc('showcase-demo@4x.png')" :srcset="utility.getImgSrcSet('showcase-demo')" alt="Demo" />
                  <div class="card-showcase-label">Quick Demo</div>
                  <div class="card-showcase-detail mt-4 mb-6">
                    ตัวอย่างเว็บไซต์สำหรับการแสดงแผนที่ออนไลน์ ด้วยการพัฒนาจาก GISTDA MAP API ไม่ว่าจะเป็นการแสดงชั้นข้อมูล (layers) หรือการค้นหาสถานที่ต่าง ๆ อย่างง่าย
                  </div>
                </div>
                <div>
                  <v-divider></v-divider>
                  <div class="text-right font-weight-light my-3">
                    <span>{{ $t('landing.seeMore') }}</span>
                    <img width="17" class="ml-3 mr-5" :src="utility.getImgSrc('arrow-right.svg')" alt="" />
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <!-- pricing -->
      <div class="landing-pricing">
        <div class="width-full slide-fade-y">
          <div class="p-relative" style="padding: 70px 0;">
            <kinesis-container class="p-absolute bg-text" event="scroll">
              <kinesis-element :strength="-30" >
                Pricing
              </kinesis-element>
            </kinesis-container>
            <div class="text-center text-transform-uppercase font-weight-medium" style="font-size: 35px;">
              Pricing
            </div>
          </div>
          <div class="text-xl d-flex justify-center px-4" style="padding-bottom: 88px;">
            ขณะนี้โครงการอยู่ในทดลองใช้งาน สามารถเข้าใช้งานได้โดยไม่เสียค่าใช้จ่าย จนกว่าจะมีแจ้งเป็นอื่น
          </div>
        </div>
      </div>
      <!-- Supported by -->
      <div class="landing-supported landing-bg-accent">
        <div class="width-full slide-fade-y">
          <div class="p-relative" style="padding: 70px 0;">
            <kinesis-container class="p-absolute bg-text" event="scroll">
              <kinesis-element :strength="-30" >
                Supported by
              </kinesis-element>
            </kinesis-container>
            <div class="text-center text-transform-uppercase font-weight-medium" style="font-size: 35px;">
              Supported by
            </div>
          </div>
          <div class="d-flex justify-center" style="padding-bottom: 103px;">
            <div class="d-sm-flex text-left px-4 pb-4">
              <!-- ml-sm-15 mt-15 mt-sm-0 -->
              <div class="card-supported-by cursor-pointer">
                <v-img height="140" contain
                  :src="utility.getImgSrc('mhe-logo.png')"
                  alt="Ministry of Higher Education, Science, Research and Innovation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <landing-footer />
    <v-btn
      color="secondary" large rounded
      class="btn-to-top text-white text-lg elevation-0"
      @click="scrollTo('html')"
    >
      <v-icon class="text-xl pl-2" left>
        mdi-arrow-up
      </v-icon>
      <span>Top</span>
    </v-btn>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
import LandingHeader from '@/components/LandingHeader.vue'
import LandingFooter from '@/components/LandingFooter.vue'

export default {
  name: 'LandingView',
  components: {
    KinesisContainer,
    KinesisElement,
    LandingHeader,
    LandingFooter
  },
  data () {
    return {
      VUE_APP_WHERE_URL: process.env.VUE_APP_WHERE_URL,
      VUE_APP_QUICK_DEMO_URL: process.env.VUE_APP_QUICK_DEMO_URL,
      VUE_APP_PORTAL_URL: process.env.VUE_APP_PORTAL_URL,
      baseURI: document.baseURI,
      fadeInElements: []
    }
  },
  mounted () {
    const section = this.$route.query.section
    if (section) {
      this.scrollTo(`.landing-${section}`)
    } else if (sessionStorage.getItem('landingScrollTo')) {
      this.scrollTo(sessionStorage.getItem('landingScrollTo'))
      sessionStorage.removeItem('landingScrollTo')
    }
    this.fadeInElements = Array.from(document.getElementsByClassName('slide-fade-y'))
    document.body.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  },
  unmounted () {
    document.body.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollTo (scrollSelector) {
      setTimeout(() => {
        const el = document.querySelector(scrollSelector)
        if (el !== null) this.$scrollTo(el, { easing: 'ease' })
      }, 100)
    },
    handleScroll () {
      for (let i = 0; i < this.fadeInElements.length; i++) {
        const elem = this.fadeInElements[i]
        if (this.utility.isElementEnteringViewport(elem)) {
          elem.classList.add('slide-in')
          this.fadeInElements.splice(i, 1) // only allow it to run once
        }
      }
    },
    changeSlide(delta) {
      const carousel = this.$el.querySelector(".my-carousel")
      carousel.scrollTo(carousel.scrollLeft + 368 * delta, 0)
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/style/landing-view.scss';
</style>
