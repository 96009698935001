import { render, staticRenderFns } from "./LocaleSwitch.vue?vue&type=template&id=e1dc5944&"
import script from "./LocaleSwitch.vue?vue&type=script&lang=js&"
export * from "./LocaleSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})


/* hot reload */
if (module.hot) {
  var api = require("/builds/thaimap/thaimap-mainweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e1dc5944')) {
      api.createRecord('e1dc5944', component.options)
    } else {
      api.reload('e1dc5944', component.options)
    }
    module.hot.accept("./LocaleSwitch.vue?vue&type=template&id=e1dc5944&", function () {
      api.rerender('e1dc5944', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LocaleSwitch.vue"
export default component.exports