var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "locale-switch d-flex" },
    [
      _c(
        "span",
        {
          class:
            _vm.$i18n.locale === "th"
              ? "font-weight-semi-bold secondary--text"
              : "cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.changeLocale("th")
            },
          },
        },
        [_vm._v("TH")]
      ),
      _c("v-divider", {
        staticClass: "mx-1",
        staticStyle: { "border-color": "white" },
        attrs: { vertical: "" },
      }),
      _c(
        "span",
        {
          class:
            _vm.$i18n.locale === "en"
              ? "font-weight-semi-bold secondary--text"
              : "cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.changeLocale("en")
            },
          },
        },
        [_vm._v("EN")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }