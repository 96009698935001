var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.isAnon || _vm.profile
        ? _c(
            "v-main",
            [
              _c("router-view"),
              _vm.showCookieBanner
                ? _c("cookie-banner", {
                    on: { "on-accept": _vm.acceptCookies },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("my-snackbar", {
        attrs: {
          showSnack: _vm.snackbar.state.showSnack,
          message: _vm.snackbar.state.message,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }