var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-view font-kanit" },
    [
      _c("landing-header", { staticClass: "p-absolute transparent-bg" }),
      _c(
        "div",
        { staticClass: "landing-view-content" },
        [
          _c(
            "v-parallax",
            {
              attrs: {
                dark: "",
                srcset: _vm.utility.getImgSrcSet("landing-bg", "jpg"),
                height: "946",
                alt: "",
              },
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  attrs: {
                    width: "269",
                    src: _vm.utility.getImgSrc("logo-sphere-alt.png", true),
                    alt: "sphere",
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "landing-intro-message slide-fade-y px-6" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-medium my-6",
                      staticStyle: {
                        "font-size": "45px",
                        "line-height": "57px",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("landing.sphereTopic")) + " ")]
                  ),
                  _c("div", {
                    staticClass: "font-weight-light text-lg mt-3",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("landing.sphereDesc")),
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-11 text-xxl text-white rounded-lg",
                      attrs: {
                        "x-large": "",
                        width: "192",
                        color: "secondary-variant",
                        elevation: "4",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "dashboard" })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("landing.start")) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "landing-tools landing-bg-accent px-4",
              staticStyle: {
                "margin-top": "-60px",
                "padding-top": "100px",
                "padding-bottom": "63px",
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "mt-15 slide-fade-y" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", sm: "6", md: "4" } },
                        [
                          _c("v-img", {
                            staticClass: "cursor-pointer mx-auto",
                            attrs: {
                              height: "62",
                              contain: "",
                              src: _vm.utility.getImgSrc("tool-map.svg"),
                              alt: "Map API",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.scrollTo(".landing-api")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", sm: "6", md: "4" } },
                        [
                          _c("v-img", {
                            staticClass: "cursor-pointer mx-auto",
                            attrs: {
                              height: "62",
                              contain: "",
                              src: _vm.utility.getImgSrc("tool-portal.svg"),
                              alt: "Open Portal",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.scrollTo(".landing-portal")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", sm: "12", md: "4" } },
                        [
                          _c("v-img", {
                            staticClass: "cursor-pointer mx-auto",
                            attrs: {
                              height: "62",
                              contain: "",
                              src: _vm.utility.getImgSrc("tool-cube.svg"),
                              alt: "Data Cube",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.scrollTo(".landing-cube")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "landing-api landing-bg-accent d-flex px-4 pb-0 px-sm-12 mx-auto",
            },
            [
              _c(
                "kinesis-container",
                { staticClass: "p-absolute", attrs: { event: "scroll" } },
                [
                  _c("kinesis-element", { attrs: { strength: 100 } }, [
                    _c("div", { staticClass: "bg-circle" }),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "width-full slide-fade-y justify-center" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "flex-grow-1 text-left pr-8",
                      staticStyle: { "max-width": "500px" },
                      attrs: { xs: "12", lg: "5" },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "p-relative" },
                          [
                            _c(
                              "kinesis-container",
                              {
                                staticClass: "p-absolute bg-text",
                                attrs: { event: "scroll" },
                              },
                              [
                                _c(
                                  "kinesis-element",
                                  { attrs: { strength: -30 } },
                                  [_vm._v(" APIs ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-transform-uppercase font-weight-medium text-center",
                                staticStyle: { "font-size": "35px" },
                              },
                              [_vm._v(" APIs ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: {
                            "font-size": "35px",
                            "line-height": "42px",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("landing.apiTopic")) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text-lg text--secondary mt-7",
                          staticStyle: { "line-height": "32px" },
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("landing.apiDesc"))),
                          ]),
                          _c("div", { staticClass: "mt-4" }, [
                            _vm._v(_vm._s(_vm.$t("landing.daasDesc"))),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-explore",
                              attrs: {
                                "x-large": "",
                                color: "primary-variant",
                                elevation: "4",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.utility.openLink(
                                    _vm.baseURI + "docs/"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" Explore the API "),
                              _c("img", {
                                staticClass: "ml-4",
                                attrs: {
                                  width: "24",
                                  src: _vm.utility.getImgSrc(
                                    "arrow-right-white.svg"
                                  ),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-lg-8 px-sm-3 px-0",
                      attrs: { xs: "12", lg: "7" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "landing-framework landing-bg-accent text-left",
                          staticStyle: { padding: "0 0 117px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-sm-flex justify-center mt-15 mt-lg-0",
                            },
                            [
                              _c("div", { staticClass: "mr-0 mr-sm-8" }, [
                                _c("div", { staticClass: "card-framework" }, [
                                  _c("img", {
                                    staticClass: "ml-4 mb-7",
                                    attrs: {
                                      src: _vm.utility.getImgSrc("api-map.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "card-framework-label" },
                                    [_vm._v("Map APIs")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mt-3 text--secondary" },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("landing.apiBoxDesc")
                                          ),
                                        },
                                      }),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/js/createmap",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("JavaScript")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/android/getting-started",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("Android")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/ios/getting-started",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("iOS")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/flutter/getting-started",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("Flutter")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/react-native/getting-started",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("React Native")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/web-service/search",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("Search API")]
                                        ),
                                        _vm._v(", "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/web-service/embed-map",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("Embed")]
                                        ),
                                        _vm._v(" / "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                _vm.baseURI +
                                                "docs/web-service/static-map",
                                            },
                                            on: { click: _vm.utility.openLink },
                                          },
                                          [_vm._v("Static Map API")]
                                        ),
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "width-full text-md rounded-lg py-2 mt-8",
                                          staticStyle: { height: "unset" },
                                          attrs: {
                                            color: "primary-variant",
                                            elevation: "4",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.utility.openLink(
                                                _vm.VUE_APP_QUICK_DEMO_URL
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-white",
                                              staticStyle: {
                                                "line-height": "1.375rem",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-lg font-weight-regular",
                                                },
                                                [_vm._v("Tiled Maps API")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-sm font-weight-light",
                                                },
                                                [
                                                  _vm._v(
                                                    "(Street, Satellite, Aerial)"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("img", {
                                            staticClass: "ml-4",
                                            attrs: {
                                              width: "24",
                                              src: _vm.utility.getImgSrc(
                                                "arrow-right-white.svg"
                                              ),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "card-framework mt-8" },
                                  [
                                    _c("img", {
                                      staticClass: "ml-n2 mt-n2 mb-2",
                                      attrs: {
                                        src: _vm.utility.getImgSrc(
                                          "api-place.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "card-framework-label" },
                                      [_vm._v("Places")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3 text--secondary" },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("landing.placeBoxDesc")
                                            ),
                                          },
                                        }),
                                        _c("span", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.baseURI +
                                                  "docs/web-service/search",
                                              },
                                              on: {
                                                click: _vm.utility.openLink,
                                              },
                                            },
                                            [_vm._v("Place Search API")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  style: {
                                    marginTop:
                                      _vm.$vuetify.breakpoint &&
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? "132px"
                                        : "32px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card-framework" }, [
                                    _c("img", {
                                      staticClass: "ml-2 mb-5",
                                      attrs: {
                                        src: _vm.utility.getImgSrc(
                                          "api-route.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "card-framework-label" },
                                      [_vm._v("Routes")]
                                    ),
                                    _c("div", {
                                      staticClass: "mt-4 text--secondary",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("landing.routeBoxDesc")
                                        ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3 text--secondary" },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("landing.routeBoxDesc")
                                            ),
                                          },
                                        }),
                                        _c("span", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.baseURI +
                                                  "docs/web-service/routing",
                                              },
                                              on: {
                                                click: _vm.utility.openLink,
                                              },
                                            },
                                            [_vm._v("Routing API")]
                                          ),
                                          _vm._v(", "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.baseURI +
                                                  "docs/web-service/routing-matrix",
                                              },
                                              on: {
                                                click: _vm.utility.openLink,
                                              },
                                            },
                                            [_vm._v("Distance Matrix API")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "card-framework mt-8" },
                                    [
                                      _c("img", {
                                        staticClass: "ml-1 mb-6",
                                        attrs: {
                                          src: _vm.utility.getImgSrc(
                                            "api-info.svg"
                                          ),
                                          alt: "",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "card-framework-label" },
                                        [_vm._v("Information")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-3 text--secondary" },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t("landing.infoBoxDesc")
                                              ),
                                            },
                                          }),
                                          _c("span", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.baseURI +
                                                    "docs/web-service/elevation-information",
                                                },
                                                on: {
                                                  click: _vm.utility.openLink,
                                                },
                                              },
                                              [_vm._v("Elevation API")]
                                            ),
                                            _vm._v(", "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.baseURI +
                                                    "docs/web-service/disaster-information",
                                                },
                                                on: {
                                                  click: _vm.utility.openLink,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Disasters API (Flood, Fire, Drought)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(","),
                                            _c("br"),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.baseURI +
                                                    "docs/web-service/crop-information",
                                                },
                                                on: {
                                                  click: _vm.utility.openLink,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Crop API (Cost & Prices, Prediction)"
                                                ),
                                              ]
                                            ),
                                            _vm._v(", "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.baseURI +
                                                    "docs/web-service/land-suitability",
                                                },
                                                on: {
                                                  click: _vm.utility.openLink,
                                                },
                                              },
                                              [_vm._v("Land Suitability API")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "landing-portal d-flex px-4 px-sm-12 mx-auto" },
            [
              _c(
                "kinesis-container",
                {
                  staticClass: "p-absolute",
                  staticStyle: { right: "64px" },
                  attrs: { event: "scroll" },
                },
                [
                  _c("kinesis-element", { attrs: { strength: 100 } }, [
                    _c("div", { staticClass: "bg-circle" }),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "width-full slide-fade-y" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-none d-sm-block pr-8",
                      attrs: { sm: "5", md: "6" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "flex-grow-1 rounded-xl",
                        attrs: {
                          "max-width": "608",
                          "aspect-ratio": 110 / 131,
                          src: _vm.utility.getImgSrc("landing-portal.jpg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "flex-grow-1 text-left pl-8",
                      attrs: { xs: "12", md: "6", sm: "7" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-relative" },
                        [
                          _c(
                            "kinesis-container",
                            {
                              staticClass: "p-absolute bg-text",
                              attrs: { event: "scroll" },
                            },
                            [
                              _c(
                                "kinesis-element",
                                { attrs: { strength: -30 } },
                                [_vm._v(" Map Maker ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-transform-uppercase font-weight-medium text-center",
                              staticStyle: { "font-size": "35px" },
                            },
                            [_vm._v(" Map Maker ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: {
                            "font-size": "35px",
                            "line-height": "42px",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("landing.portalTopic")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text-lg text--secondary mt-7",
                          staticStyle: { "line-height": "32px" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("landing.portalDesc")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-explore",
                              attrs: {
                                "x-large": "",
                                color: "primary-variant",
                                elevation: "4",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.utility.openLink(
                                    _vm.VUE_APP_PORTAL_URL
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" Explore the Portal "),
                              _c("img", {
                                staticClass: "ml-4",
                                attrs: {
                                  width: "24",
                                  src: _vm.utility.getImgSrc(
                                    "arrow-right-white.svg"
                                  ),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "kinesis-container",
            { staticClass: "p-absolute", attrs: { event: "scroll" } },
            [
              _c("kinesis-element", { attrs: { strength: 100 } }, [
                _c("div", { staticClass: "bg-circle" }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "landing-cube d-flex px-4 px-sm-12 mx-auto" },
            [
              _c(
                "v-row",
                { staticClass: "width-full slide-fade-y" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "flex-grow-1 text-left pr-8",
                      attrs: { xs: "12", md: "6", sm: "7" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-relative" },
                        [
                          _c(
                            "kinesis-container",
                            {
                              staticClass: "p-absolute bg-text",
                              attrs: { event: "scroll" },
                            },
                            [
                              _c(
                                "kinesis-element",
                                { attrs: { strength: -30 } },
                                [_vm._v(" Data Cube ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-transform-uppercase font-weight-medium text-center",
                              staticStyle: { "font-size": "35px" },
                            },
                            [_vm._v(" Data Cube ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: {
                            "font-size": "35px",
                            "line-height": "42px",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("landing.cubeTopic")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "text-lg text--secondary mt-7",
                          staticStyle: { "line-height": "32px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("landing.cubeDesc")) + " ")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-explore",
                              attrs: {
                                "x-large": "",
                                color: "primary-variant",
                                elevation: "4",
                                href: "https://datacube.gistda.or.th/",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(" Explore the Data Cube "),
                              _c("img", {
                                staticClass: "ml-4",
                                attrs: {
                                  width: "24",
                                  src: _vm.utility.getImgSrc(
                                    "arrow-right-white.svg"
                                  ),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-none d-sm-block pl-8",
                      attrs: { sm: "5", md: "6" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "flex-grow-1 rounded-xl",
                        attrs: {
                          "max-width": "608",
                          "aspect-ratio": 110 / 131,
                          src: _vm.utility.getImgSrc("landing-odc.jpg"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "landing-showcase landing-bg-accent",
              staticStyle: { "padding-bottom": "104px" },
            },
            [
              _c("div", { staticClass: "width-full slide-fade-y" }, [
                _c(
                  "div",
                  {
                    staticClass: "p-relative",
                    staticStyle: { padding: "70px 0" },
                  },
                  [
                    _c(
                      "kinesis-container",
                      {
                        staticClass: "p-absolute bg-text",
                        attrs: { event: "scroll" },
                      },
                      [
                        _c("kinesis-element", { attrs: { strength: -30 } }, [
                          _vm._v(" Solutions "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center text-transform-uppercase font-weight-medium",
                        staticStyle: { "font-size": "35px" },
                      },
                      [_vm._v(" Solutions ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-relative d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "carousel-btn left-btn",
                        attrs: {
                          outlined: "",
                          fab: "",
                          small: "",
                          elevation: "0",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeSlide(-1)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-arrow-left"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "carousel-btn right-btn",
                        attrs: {
                          outlined: "",
                          fab: "",
                          small: "",
                          elevation: "0",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeSlide(1)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-arrow-right"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "my-carousel d-flex text-left px-4 pb-4" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "card-showcase",
                            attrs: { elevation: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  _vm.VUE_APP_WHERE_URL
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "368",
                                    height: "231",
                                    contain: "",
                                    src: _vm.utility.getImgSrc(
                                      "showcase-where.png"
                                    ),
                                    alt: "Where",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card-showcase-label" },
                                  [_vm._v("Where")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-showcase-detail mt-4 mb-6",
                                  },
                                  [
                                    _vm._v(
                                      " ระบบการค้นหาเส้นทางแบบ Turn by turn navigation ที่ผู้ใช้งานสามารถป้อนจุดเริ่มต้น และจุดปลายทางได้ พร้อมทั้งมีเสียงนำทางประกอบ สามารถ download ได้ทั้งบน iOS และ Android "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("v-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right font-weight-light my-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("landing.seeMore"))),
                                    ]),
                                    _c("img", {
                                      staticClass: "ml-3 mr-5",
                                      attrs: {
                                        width: "17",
                                        src: _vm.utility.getImgSrc(
                                          "arrow-right.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "card-showcase ml-11",
                            attrs: { elevation: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  "https://opendata.gistda.or.th/better_crop"
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "368",
                                    height: "231",
                                    contain: "",
                                    src: _vm.utility.getImgSrc(
                                      "showcase-crop@2x.png"
                                    ),
                                    srcset:
                                      _vm.utility.getImgSrcSet("showcase-crop"),
                                    alt: "Better Crop",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card-showcase-label" },
                                  [_vm._v("Better Crop")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-showcase-detail mt-4 mb-6",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่เหมาะสมในการปลูกพืชเศรษกิจ 6 ชนิด"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "ได้แก่ อ้อย ข้าว ข้าวโพด มันสำปะหลัง ยางพารา และปาล์ม "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "เพียงผู้ใช้งานวาดขอบเขตบริเวณพื้นที่ที่สนใจ ระบบจะทำการการวิเคราะห์พื้นที่ และแสดงความเหมาะสมของพืชแต่ละชนิด รวมถึงราคาต้นทุนในการเพาะปลูกและราคาขายในปัจจุบัน ได้อีกด้วย"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("v-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right font-weight-light my-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("landing.seeMore"))),
                                    ]),
                                    _c("img", {
                                      staticClass: "ml-3 mr-5",
                                      attrs: {
                                        width: "17",
                                        src: _vm.utility.getImgSrc(
                                          "arrow-right.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "card-showcase ml-11",
                            attrs: { elevation: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  "https://opendata.gistda.or.th/drought-info"
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "368",
                                    height: "231",
                                    contain: "",
                                    src: _vm.utility.getImgSrc(
                                      "showcase-drought@2x.png"
                                    ),
                                    srcset:
                                      _vm.utility.getImgSrcSet(
                                        "showcase-drought"
                                      ),
                                    alt: "Drought Information",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card-showcase-label" },
                                  [_vm._v("Drought Information")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-showcase-detail mt-4 mb-6",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลภัยแล้งซ้ำซาก 10 ปี (พ.ศ. 2548 -2557) ด้วยการแสดงในรูปแบบแผนที่ออนไลน์"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "ผู้ใช้งานสามารถเลือกแสดงผลเปอร์เซ็นต์ของระดับภัยแล้งซ้ำซากในระดับตำบล โดยการเลือกจังหวัดและอำเภอที่สนใจ"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("v-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right font-weight-light my-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("landing.seeMore"))),
                                    ]),
                                    _c("img", {
                                      staticClass: "ml-3 mr-5",
                                      attrs: {
                                        width: "17",
                                        src: _vm.utility.getImgSrc(
                                          "arrow-right.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "card-showcase ml-11",
                            attrs: { elevation: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  "https://opendata.gistda.or.th/flood_info"
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "368",
                                    height: "231",
                                    contain: "",
                                    src: _vm.utility.getImgSrc(
                                      "showcase-flood@2x.png"
                                    ),
                                    srcset:
                                      _vm.utility.getImgSrcSet(
                                        "showcase-flood"
                                      ),
                                    alt: "Flood Information",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card-showcase-label" },
                                  [_vm._v("Flood Information")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-showcase-detail mt-4 mb-6",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่น้ำท่วมซ้ำซากในรอบ 11 ปี (พ.ศ. 2554 - พ.ศ. 2564)"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "โดยผู้ใช้งานสามารถเลือกสอบถามพื้นที่น้ำท่วมจากการเลือกขอบเขตจังหวัดและอำเภอ"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "หรือสอบถามพื้นที่น้ำท่วมโดยรอบขอบเขตของการปักหมุดบนแผนที่ที่สนใจได้"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("v-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right font-weight-light my-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("landing.seeMore"))),
                                    ]),
                                    _c("img", {
                                      staticClass: "ml-3 mr-5",
                                      attrs: {
                                        width: "17",
                                        src: _vm.utility.getImgSrc(
                                          "arrow-right.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "card-showcase ml-11",
                            attrs: { elevation: "0" },
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  _vm.VUE_APP_QUICK_DEMO_URL
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-img", {
                                  attrs: {
                                    width: "368",
                                    height: "231",
                                    contain: "",
                                    src: _vm.utility.getImgSrc(
                                      "showcase-demo@4x.png"
                                    ),
                                    srcset:
                                      _vm.utility.getImgSrcSet("showcase-demo"),
                                    alt: "Demo",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "card-showcase-label" },
                                  [_vm._v("Quick Demo")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-showcase-detail mt-4 mb-6",
                                  },
                                  [
                                    _vm._v(
                                      " ตัวอย่างเว็บไซต์สำหรับการแสดงแผนที่ออนไลน์ ด้วยการพัฒนาจาก GISTDA MAP API ไม่ว่าจะเป็นการแสดงชั้นข้อมูล (layers) หรือการค้นหาสถานที่ต่าง ๆ อย่างง่าย "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("v-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-right font-weight-light my-3",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("landing.seeMore"))),
                                    ]),
                                    _c("img", {
                                      staticClass: "ml-3 mr-5",
                                      attrs: {
                                        width: "17",
                                        src: _vm.utility.getImgSrc(
                                          "arrow-right.svg"
                                        ),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "landing-pricing" }, [
            _c("div", { staticClass: "width-full slide-fade-y" }, [
              _c(
                "div",
                {
                  staticClass: "p-relative",
                  staticStyle: { padding: "70px 0" },
                },
                [
                  _c(
                    "kinesis-container",
                    {
                      staticClass: "p-absolute bg-text",
                      attrs: { event: "scroll" },
                    },
                    [
                      _c("kinesis-element", { attrs: { strength: -30 } }, [
                        _vm._v(" Pricing "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center text-transform-uppercase font-weight-medium",
                      staticStyle: { "font-size": "35px" },
                    },
                    [_vm._v(" Pricing ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-xl d-flex justify-center px-4",
                  staticStyle: { "padding-bottom": "88px" },
                },
                [
                  _vm._v(
                    " ขณะนี้โครงการอยู่ในทดลองใช้งาน สามารถเข้าใช้งานได้โดยไม่เสียค่าใช้จ่าย จนกว่าจะมีแจ้งเป็นอื่น "
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "landing-supported landing-bg-accent" }, [
            _c("div", { staticClass: "width-full slide-fade-y" }, [
              _c(
                "div",
                {
                  staticClass: "p-relative",
                  staticStyle: { padding: "70px 0" },
                },
                [
                  _c(
                    "kinesis-container",
                    {
                      staticClass: "p-absolute bg-text",
                      attrs: { event: "scroll" },
                    },
                    [
                      _c("kinesis-element", { attrs: { strength: -30 } }, [
                        _vm._v(" Supported by "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center text-transform-uppercase font-weight-medium",
                      staticStyle: { "font-size": "35px" },
                    },
                    [_vm._v(" Supported by ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center",
                  staticStyle: { "padding-bottom": "103px" },
                },
                [
                  _c("div", { staticClass: "d-sm-flex text-left px-4 pb-4" }, [
                    _c(
                      "div",
                      { staticClass: "card-supported-by cursor-pointer" },
                      [
                        _c("v-img", {
                          attrs: {
                            height: "140",
                            contain: "",
                            src: _vm.utility.getImgSrc("mhe-logo.png"),
                            alt: "Ministry of Higher Education, Science, Research and Innovation",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("landing-footer"),
      _c(
        "v-btn",
        {
          staticClass: "btn-to-top text-white text-lg elevation-0",
          attrs: { color: "secondary", large: "", rounded: "" },
          on: {
            click: function ($event) {
              return _vm.scrollTo("html")
            },
          },
        },
        [
          _c("v-icon", { staticClass: "text-xl pl-2", attrs: { left: "" } }, [
            _vm._v(" mdi-arrow-up "),
          ]),
          _c("span", [_vm._v("Top")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }