var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-footer" },
    [
      _c(
        "v-row",
        { staticClass: "align-center px-5 py-5", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "12", xs: "12", sm: "5", md: "5" } }, [
            _c("div", { staticClass: "d-block d-md-flex align-center" }, [
              _c("img", {
                staticClass: "mt-2 ml-md-10",
                attrs: {
                  height: "95",
                  src: _vm.utility.getImgSrc("landing-header-logo@4x.png"),
                  srcset: _vm.utility.getImgSrcSet("landing-header-logo"),
                  alt: "Gistda platform",
                },
              }),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", xs: "12", sm: "2", md: "2" } }, [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "footer-logo mx-1 my-2",
                    attrs: { dark: "", width: "58", height: "58", icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.utility.openLink(
                          "mailto:sphere@gistda.or.th"
                        )
                      },
                    },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        contain: "",
                        height: "42",
                        width: "42",
                        src: _vm.utility.getImgSrc("mail-logo.svg"),
                        alt: "Mail",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "footer-logo mx-1 my-2",
                    attrs: { dark: "", width: "58", height: "58", icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.utility.openLink(
                          "https://www.facebook.com/gistda"
                        )
                      },
                    },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        contain: "",
                        height: "42",
                        width: "42",
                        src: _vm.utility.getImgSrc("fb-logo.svg"),
                        alt: "Mail",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "footer-logo mx-1 my-2",
                    attrs: { dark: "", width: "58", height: "58", icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.utility.openLink(
                          "https://www.instagram.com/gistda_space"
                        )
                      },
                    },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        contain: "",
                        height: "42",
                        width: "42",
                        src: _vm.utility.getImgSrc("ig-logo.svg"),
                        alt: "Mail",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", xs: "12", sm: "5", md: "5" } }, [
            _c("div", { staticClass: "footer-text my-2 text-sm-right" }, [
              _vm._v(" " + _vm._s(_vm.$t("landing.footerText1"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("landing.footerText2"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("landing.footerText3"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("landing.footerText4")) + " "),
              _c(
                "a",
                {
                  staticClass: "text-white text-decoration-none",
                  attrs: { href: "tel:021430567" },
                },
                [_vm._v("02 143 0567")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }