var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "my-snackbar mb-4",
      attrs: { "data-cy": "my-snackbar", timeout: 4000 },
      model: {
        value: _vm.snackState,
        callback: function ($$v) {
          _vm.snackState = $$v
        },
        expression: "snackState",
      },
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }