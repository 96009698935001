var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cookie-banner" }, [
    _c("div", [
      _c("p", [
        _vm._v(
          " เว็บไซต์นี้ใช้คุกกี้เพื่อวัตถุประสงค์ในการปรับปรุงประสบการณ์ของผู้ใช้ให้ดียิ่งขึ้น กรุณาศึกษารายละเอียด "
        ),
        _c(
          "a",
          {
            staticClass: "secondary--text",
            attrs: { href: _vm.$router.resolve({ name: "privacy" }).href },
            on: { click: _vm.utility.openLink },
          },
          [_vm._v("นโยบายความเป็นส่วนตัว")]
        ),
      ]),
      _c("p", [
        _vm._v(
          " This site uses cookies from Google Analytics to give you the best experience on our site. "
        ),
        _c(
          "a",
          {
            staticClass: "secondary--text",
            attrs: { href: _vm.$router.resolve({ name: "privacy" }).href },
            on: { click: _vm.utility.openLink },
          },
          [_vm._v("Privacy Policy")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "text-right" },
      [
        _c(
          "v-btn",
          { attrs: { color: "secondary" }, on: { click: _vm.accept } },
          [_vm._v("ยอมรับ / Accept")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }