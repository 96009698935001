var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-header" },
    [
      _vm.$vuetify.breakpoint && _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                "mobile-breakpoint": "",
                color: "primary",
                dark: "",
              },
            },
            [
              _c("v-app-bar-nav-icon", {
                staticClass: "mr-5",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
              _c(
                "router-link",
                { staticClass: "ma-auto", attrs: { to: "/" } },
                [
                  _c("img", {
                    staticClass: "mt-2",
                    attrs: {
                      "max-width": "111",
                      height: "36",
                      contain: "",
                      src: _vm.utility.getImgSrc("logo-sphere-alt.png", true),
                      alt: "sphere",
                    },
                  }),
                ]
              ),
              _c("locale-switch"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "primary d-flex d-md-none",
          attrs: {
            app: "",
            dark: "",
            "mobile-breakpoint": "",
            temporary: "",
            "hide-overlay": "",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pa-8 pb-5 text-white text-left" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-5",
                  attrs: {
                    disabled: "",
                    elevation: "3",
                    color: "#c3c3c3",
                    fab: "",
                    height: "45",
                    width: "45",
                  },
                },
                [
                  _vm.profileImg
                    ? _c("v-img", {
                        staticStyle: { "border-radius": "32px" },
                        attrs: {
                          width: "25",
                          src: _vm.profileImg,
                          alt: "Profile Image",
                        },
                      })
                    : _c("v-img", {
                        staticClass: "ma-4",
                        attrs: {
                          width: "25",
                          src: _vm.utility.getImgSrc("ic-account-white.svg"),
                          alt: "Profile Image",
                        },
                      }),
                ],
                1
              ),
              _vm.profile && _vm.profile.id
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "one-line text-lg word-break-all font-weight-semi-bold",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.profile.displayName || _vm.profile.username
                          )
                        ),
                      ]
                    ),
                    _vm.profile.organization
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-sm font-weight-light one-line word-break-all",
                          },
                          [_vm._v(_vm._s(_vm.profile.organization))]
                        )
                      : _vm._e(),
                  ])
                : _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-white",
                          attrs: { color: "secondary-variant" },
                          on: { click: _vm.login },
                        },
                        [_vm._v(" Log in ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { outlined: "", color: "#FFF" },
                          on: { click: _vm.login },
                        },
                        [_vm._v(" Sign up ")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { staticClass: "mt-9" },
            _vm._l(_vm.navigationItems, function (item) {
              return _c(
                "v-list-item",
                {
                  key: item.title,
                  staticClass: "text-white py-1 pl-7",
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onNavigationClick(item)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          item.icon
                            ? _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(item.icon)),
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "text-lg" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-4 d-none d-md-flex" },
        [
          _c(
            "router-link",
            {
              class: _vm.$route.name === "landing" ? "d-none" : "",
              attrs: { to: "/" },
            },
            [
              _c("img", {
                staticClass: "ml-8 ml-lg-15 mt-2",
                attrs: {
                  height: "85",
                  contain: "",
                  src: _vm.utility.getImgSrc("logo-sphere-alt.png", true),
                  alt: "sphere",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "landing-navigation d-none d-md-flex align-center mr-8 mr-lg-15 text-lg",
        },
        [
          _vm._l(_vm.navigationItems, function (item) {
            return _c("div", { key: item.title, staticClass: "mx-2 mx-lg-4" }, [
              _c(
                "a",
                {
                  staticClass: "navigation-link",
                  on: {
                    click: function ($event) {
                      return _vm.onNavigationClick(item)
                    },
                  },
                },
                [
                  item.icon
                    ? _c("v-icon", { attrs: { color: "#FFF" } }, [
                        _vm._v("mdi-help-circle-outline"),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(item.title) + " "),
                ],
                1
              ),
            ])
          }),
          _c("locale-switch"),
          _vm.profile && _vm.profile.id
            ? _c("div", { staticClass: "user-panel d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "ml-8" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: "",
                          elevation: "3",
                          color: "#c3c3c3",
                          fab: "",
                          height: "45",
                          width: "45",
                        },
                      },
                      [
                        _vm.profileImg
                          ? _c("v-img", {
                              staticStyle: { "border-radius": "32px" },
                              attrs: {
                                width: "25",
                                src: _vm.profileImg,
                                alt: "Profile Image",
                              },
                            })
                          : _c("v-img", {
                              staticClass: "ma-4",
                              attrs: {
                                width: "25",
                                src: _vm.utility.getImgSrc(
                                  "ic-account-white.svg"
                                ),
                                alt: "Profile Image",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "main-header-profile text-left ml-3",
                    staticStyle: { width: "90px", "line-height": "1.25rem" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "one-line text-lg word-break-all font-weight-semi-bold",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.profile.displayName || _vm.profile.username
                          )
                        ),
                      ]
                    ),
                    _vm.profile.organization
                      ? _c(
                          "div",
                          {
                            staticClass:
                              " text-gray text-sm font-weight-light one-line word-break-all",
                          },
                          [_vm._v(_vm._s(_vm.profile.organization))]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "", left: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-3",
                                          attrs: { icon: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "header-icon text-white",
                                          attrs: { large: "" },
                                        },
                                        [_vm._v("mdi-menu-down")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1365223273
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { staticClass: "text-left py-0" },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { to: "/dashboard" } },
                              [_c("v-list-item-title", [_vm._v("Dashboard")])],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-list-item",
                              { on: { click: _vm.logout } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$t("app.logout"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "login-panel d-flex align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-white ml-8",
                      attrs: { color: "secondary-variant" },
                      on: { click: _vm.login },
                    },
                    [_vm._v(" Log in ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-6",
                      attrs: {
                        outlined: "",
                        color:
                          _vm.$route.name === "login" ||
                          _vm.$route.name === "signup"
                            ? "secondary-variant"
                            : "#FFF",
                      },
                      on: { click: _vm.signup },
                    },
                    [_vm._v(" Sign up ")]
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }